import React, {useState} from 'react'
import classnames from 'classnames'
import DealHeader from "./DealHeader";
import {DealPageFees} from "./DealPageFees";
import {DealPagePayment} from "./DealPagePayment";
import {useDealContext} from "../../contexts/DealContext";
import {DealPageRebates} from "./DealPageRebates";

import Box from "@mui/material/Box";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Stack from "@mui/material/Stack";
import {DealPageInfo} from "./DealPageInfo";
import './DealPage.scss'
import {LocationModal} from "./LocationModal";
import {useModalContext} from "../../contexts/ModalContext";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {DealPageTaxes} from "./DealPageTaxes";
import Collapse from "@mui/material/Collapse";
import {DealPageDrawer} from "./DealPageDrawer";

export const DealPage = ( { isModal } ) => {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { dealState, setDealStateWithUpdate, dealPageRef, updateDealResponse } = useDealContext()
    const {
        isLocationModalOpen,
        setIsLocationModalOpen,
        isDeskingModalOpen,
        setIsDeskingModalOpen,
    } = useModalContext()

    const saveDealLocation = (location) => {
        setIsLocationModalOpen(false)
        setDealStateWithUpdate({location_id: location.id, location: location}, true)
    }

    if (!dealState) return null;

    const isPurchase = dealState.is_purchase
    const DrawerIcon = drawerOpen ? ArrowBackIosIcon : ArrowForwardIosIcon

    return (
            <Box
                id={"DealPage"}
                className={classnames('', {
                    isPurchase: isPurchase
                })}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative'
                    }}
                >
                    <div>
                        <div ref={dealPageRef}/>
                        <DealHeader
                            isModal={isModal}
                            dealState={dealState}
                            setDealStateWithUpdate={setDealStateWithUpdate}
                        />

                        <DealPageInfo dealState={dealState}  setDealStateWithUpdate={setDealStateWithUpdate}/>
                        <DealPagePayment isDealPage={true}/>

                        <Stack
                            style={{
                                display: isTabletOrMobile ? 'flex' : 'block',
                            }}
                            direction={isTabletOrMobile ? 'column' : 'row'}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <DealPageRebates/>

                            <DealPageFees/>

                            <DealPageTaxes/>
                        </Stack>
                        {
                            isLocationModalOpen &&
                            <LocationModal
                                open={isLocationModalOpen}
                                setIsOpen={() => setIsLocationModalOpen(false)}
                                onClose={() => setIsLocationModalOpen(false)}
                                initialLocation={dealState.location}
                                selectLocation={saveDealLocation}
                                isSaving={updateDealResponse?.loading}
                            />
                        }

                    </div>
                    {
                        !isTabletOrMobile &&
                        <div>
                            <Collapse in={drawerOpen} orientation="horizontal">
                                <DealPageDrawer/>
                            </Collapse>
                            <DrawerIcon
                                onClick={() => setDrawerOpen(!drawerOpen)}
                                style={{
                                    color: "#666666",
                                    position: 'absolute',
                                    right: '-36px',
                                    top: `calc(100vh / 2)`,
                                    cursor: "pointer"
                                }}
                            />
                        </div>
                    }
                </Box>
        </Box>
    )
}