import React, { useState } from 'react'

import QuoteHelper from "../../utils/QuoteHelper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IncentiveHelper from "../../utils/IncentiveHelper";
import Box from "@mui/material/Box";
import {DealPageRebateItem} from "./DealPageRebateItem";
import {CreateRebateItem} from "./CreateRebateItem";
import {CustomDealPageRebateItem} from "./CustomDealPageRebateItem";
import FeeHelper from "../../utils/FeeHelper";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";

import classnames from "classnames";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import {NumericFormat} from "react-number-format";
import {useDealContext} from "../../contexts/DealContext";

export const DealPageRebates = () => {
    const { setDealStateWithUpdate, dealState } = useDealContext()
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const [open, setOpen] = useState(false);
    if (!dealState || !dealState.selected_quote) return null;

    const helper = new QuoteHelper(dealState)
    const incentives = helper.incentives()
    const dealerRebateFees = helper.dealerRebateFees()

    return (
        <Box className={classnames("dealPageRebates", {
            mobile: isTabletOrMobile
        })}>
            <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="center"
                className={'header header-row'}
            >
                <div
                    className={'header-name'}
                >
                    Rebates
                </div>
                <Stack
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div className={'header-amount'}>
                        <NumericFormat
                            value={helper.totalRebatesAmount()}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </div>
                    <div>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </div>
                </Stack>
            </Stack>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell
                            className={'header-cell'}
                            align="left"
                            width={isTabletOrMobile ? '10%' : '40%'}
                        >
                            Name
                        </TableCell>
                        <TableCell
                            className={'header-cell'}
                            align="right"
                        >
                            Amount
                        </TableCell>
                        <TableCell
                            className={'header-cell'}
                            align="right"
                        >
                            Applied
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <CreateRebateItem
                        dealState={dealState}
                        setDealStateWithUpdate={setDealStateWithUpdate}
                    />
                    {
                        incentives.map((incentive, index) => {
                                const incentiveHelper = new IncentiveHelper(incentive)
                                return (
                                    <DealPageRebateItem
                                        key={incentiveHelper.uniqueId()}
                                        incentive={incentive}
                                        dealState={dealState}
                                        setDealStateWithUpdate={setDealStateWithUpdate}
                                        isSelected={Boolean(helper.findDealerRebate(incentiveHelper.subTitleId()))}
                                    />
                                )
                            }

                        )
                    }
                    {
                        dealerRebateFees.map((fee, index) => {
                            const helper = new FeeHelper(fee)
                            return (
                                <CustomDealPageRebateItem
                                    key={helper.uniqueId()}
                                    fee={fee}
                                    dealState={dealState}
                                    setDealStateWithUpdate={setDealStateWithUpdate}
                                />
                            )
                        })
                    }
                </TableBody>
            </Table>
            </Collapse>
        </Box>
    )
}