import React from "react";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import classnames from "classnames";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {buildTrimAndStyleDescriptionName} from "../../utils/utils";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {useDealContext} from "../../contexts/DealContext";
import {PopoverInput} from "../../components/PopoverInput";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import {useModalContext} from "../../contexts/ModalContext";
import Tooltip from "@mui/material/Tooltip";

export const DealPageInfo = ( { setDealStateWithUpdate }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { updateDealResponse, dealState } = useDealContext()
    const { setIsLocationModalOpen } = useModalContext()

    const car = dealState.car_detail
    const isLoading = updateDealResponse?.loading
    const location = dealState.location
    const isPurchase = dealState.is_purchase

    return (
        <Stack
            style={{
                // marginTop: '30px',
                // display: isTabletOrMobile ? 'flex' : 'block',
            }}
            direction={isTabletOrMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
        >
            <div className={'DealPageInfo_box-container'}>
                <Box
                    className={'DealPageInfo_box'}
                     style={{
                         marginRight: '20px'
                     }}
                >
                    <Typography
                        variant="h5"
                        component="div"
                        className={'header-title'}
                        sx={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            // marginBottom: '10px',
                        }}
                    >
                        { car.year } { car.make } { car.model }
                        <Typography
                            variant="subtitle1"
                            component="div"
                            className={'header-subtitle'}
                        >
                            { buildTrimAndStyleDescriptionName(car.trim, car.style_description) }
                        </Typography>
                    </Typography>
                    <Divider className={'divider'}/>

                    <Typography component="div">
                        <PopoverInput
                            inputProps={{
                                autoFocus: false,
                                placeholder: isLoading ? '' : 'Description'
                            }}
                            spellCheck={!isLoading}
                            className={classnames('DealPageInfo_description', {
                                loading: isLoading
                            })}
                            id="txtArea"
                            rows="8"
                            cols="40"
                            onChange={(text) => setDealStateWithUpdate({description: text})}
                            value={dealState?.description || ''}
                            placeholder={isLoading ? '' : 'Description'}
                        />
                    </Typography>

                </Box>
                <Box className={'DealPageInfo_box'}>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={classnames('header location-info', {
                        isPurchase: isPurchase
                    })}
                    sx={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    }}
                >
                    <span>Location</span>
                    <Tooltip title="Edit Location" arrow placement={'top'}>
                        <EditLocationIcon
                            className={'action-icon'}
                            onClick={() => setIsLocationModalOpen(true)}
                        />
                    </Tooltip>
                </Typography>
                <Divider className={'divider'}/>
                <Typography
                    sx={{
                        fontSize: '16px',
                        marginTop: '10px',
                        color: '#666'
                    }}
                >
                    { location.city }, {' '}
                    { location.county }, {' '}
                    { location.state }
                </Typography>
            </Box>
            </div>
        </Stack>
    )
}